import endOfYesterday from 'date-fns/end_of_yesterday'
import isBefore from 'date-fns/is_before'
import lastDayOfYear from 'date-fns/last_day_of_year'
import startOfYear from 'date-fns/start_of_year'
import subMonths from 'date-fns/sub_months'
import subYears from 'date-fns/sub_years'

import {
  DATE_RANGE,
  FROM_BEGINNING,
  LAST_YEAR,
  PAST_MONTH,
  PAST_YEAR,
  SIX_MONTHS,
  YEAR_TO_DATE,
} from '../constants/YieldHistory'

export function nowInSeconds() {
  const dateTime = Date.now()
  return Math.floor(dateTime / 1000)
}

// Customers should not have any history before this date
export const EARLIEST_POSSIBLE_DATE = new Date(2007, 0, 1)
export const EARLIEST_POSSIBLE_DATE_CONVERTED = new Date(2022, 0, 1)

export function isDateMoreThanElevenMonthsAgo(date: Date) {
  return isBefore(date, subMonths(Date.now(), 11))
}

export function isDateMoreThanYearAgo(date: Date) {
  return isBefore(date, subMonths(Date.now(), 12))
}

export function isDateMoreThanThirteenMonthsAgo(date: Date) {
  return isBefore(date, subMonths(Date.now(), 13))
}

export function isDateUpcomingInTwoMonths(date: Date) {
  return isBefore(subMonths(date, 2), Date.now())
}

export function today(): Date {
  return new Date()
}

export function yesterday(): Date {
  return endOfYesterday()
}

export function dateMonthAgo(): Date {
  return subMonths(today(), 1)
}

export function dateSixMonthsAgo(): Date {
  return subMonths(today(), 6)
}

export function dateYearAgo(): Date {
  return subYears(today(), 1)
}

export function firstDayOfThisYear(): Date {
  return firstDayOfYear(today())
}

export function lastDayOfPreviousYear(): Date {
  return lastDayOfYear(dateYearAgo())
}

export function firstDayOfPreviousYear(): Date {
  return firstDayOfYear(dateYearAgo())
}

export function firstDayOfYear(date: Date): Date {
  return startOfYear(date)
}

export function firstOf1970(): Date {
  return new Date(0)
}

export function firstOf2007(): Date {
  return new Date(2007, 0, 1)
}

export function firstOf2022(): Date {
  return new Date(2022, 0, 1)
}

export function getPreviousYear(date: Date) {
  return date.getFullYear() - 1
}

export function dateRangeToDates(
  dateRange: DATE_RANGE,
  restricted?: boolean
): {
  startDate?: Date
  endDate?: Date
} {
  switch (dateRange) {
    case PAST_MONTH:
      return { startDate: dateMonthAgo(), endDate: yesterday() }
    case PAST_YEAR:
      return { startDate: dateYearAgo(), endDate: yesterday() }
    case LAST_YEAR:
      return {
        startDate: firstDayOfPreviousYear(),
        endDate: lastDayOfPreviousYear(),
      }
    case SIX_MONTHS:
      return {
        startDate: dateSixMonthsAgo(),
        endDate: yesterday(),
      }
    case YEAR_TO_DATE:
      return {
        startDate: firstDayOfThisYear(),
        endDate: yesterday(),
      }
    case FROM_BEGINNING:
      return {
        startDate: restricted === true ? firstOf2022() : firstOf2007(),
        endDate: yesterday(),
      }
  }
  return { startDate: undefined, endDate: undefined }
}
